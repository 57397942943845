
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import EntityTable from "@/components/EntityTable.vue";

export default defineComponent({
  name: "kt-widget-12",
  components: {
    EntityTable,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      columns: [
        {
          name: "id",
          label: "Id",
        },
        {
          name: "name",
          label: "Nombre del Perfil",
        },
        {
          name: "permissions",
          label: "Permisos",
        },
        {
          name: "updated_at",
          label: "Última Modificación",
        },
      ],
    };
  },
});
