import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "badge badge-light-primary mx-2 my-2" }
const _hoisted_2 = { class: "badge badge-light-primary mx-2 my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityTable = _resolveComponent("EntityTable")!

  return (_openBlock(), _createBlock(_component_EntityTable, {
    "entity-name": "Perfil",
    "resource-name": "roles",
    "route-prefix": "role",
    "permission-prefix": "role",
    "columns-data": _ctx.columns,
    "show-pagination": true,
    "show-create": true,
    "show-edit": true,
    "show-actions": false,
    "show-quickfilter": false
  }, {
    "column-permissions": _withCtx((role) => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(role.entity.permissions.length) + " permisos ", 1)
    ]),
    "column-users": _withCtx((role) => [
      _createElementVNode("span", _hoisted_2, _toDisplayString(role.entity.users.length) + " permisos ", 1)
    ]),
    _: 1
  }, 8, ["columns-data"]))
}